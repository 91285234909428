<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {minLength, required} from "vuelidate/lib/validators";
import Master from "@/apis/Master";
import Swal from "sweetalert2";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "ADMIN SECTIONS",
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "ADMIN SECTIONS",
          active: true
        }
      ],
      sectionData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      selected:"true",
      fields: [
        { key: "slNo", sortable: false, label: "Sl No",  thStyle: { color: "black", "font-size":"16px"}},
        { key: "pageSection", sortable: true, label: "SECTION NAME",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "status", sortable: true, label: "STATUS",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "action", label: "ACTION", sortable: false,  thStyle: { color: "black", "font-size":"16px"}}
      ],
      section: {
        name: "",
        status: "1"
      },
      editSection:{
        id: "",
        name: "",
        status: ""
      },
      submitted: false,
      editSubmitted: false,
      showmodal: false,
      editData: [],
      successMessage: "",
      errorMessage: "",
      editshowmodal: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.sectionData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.sectionData.length;

    Master.adminSectionList().then((res) => {
      console.log(res)
      this.sectionData = res.data.data;
    })
  },
  validations: {
    section: {
      name: { required, minLength: minLength(3) }
    },
    editSection:{
      name: { required, minLength: minLength(3) }
    }
  },
  methods: {
    /**
     * Search the table data with search input
     */

    editModal(data){
      this.editshowmodal = true
      Master.adminSectionDetails({
        id:data
      }).then((res) => {
        this.editSection.id = res.data.data.id
        this.editSection.name = res.data.data.sectionName
        this.editSection.status = res.data.data.status
        console.log(res)
      })
    },

    async deleteVendor(data){
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          Master.adminSectionDelete(
              {
                id: data
              }).then(res => {
            console.log(res)
            Swal.fire("Deleted!", "Your data has been deleted.", "success");
            // this.successMessage = res.data.message;
            Master.adminSectionList().then((res) => {
              console.log(res)
              this.sectionData = res.data.data;
            })
          })
        }
      });
    },

    async handleSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.section.$invalid) {
        return;
      } else {
        Master.adminSectionAdd(
            {
              sectionName : this.section.name,
              status : this.section.status,
            }).then((res) => {
          console.log(res)
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          // this.successMessage = res.data.message;

          Master.adminSectionList().then((res) => {
            console.log(res)
            this.sectionData = res.data.data;
          })
          this.showmodal = false;
        }).catch((err) => {
          console.log(err)
          // this.errorMessage = err.response.data.error.name[0];
          this.$bvToast.toast(""+err.response.data.error.sectionName[0]+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });
          this.showmodal = false;
        })

      }
      this.submitted = false;
      this.section.name = "";
      this.section.status = "1";
    },

    handleUpdate(){
      this.editSubmitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.editSection.$invalid) {
        return;
      } else {
        Master.adminSectionUpdate(
            {
              id: this.editSection.id,
              sectionName : this.editSection.name,
              status : this.editSection.status
            }).then((res) => {
          console.log(res)
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          Master.adminSectionList().then((res) => {
            console.log(res)
            this.sectionData = res.data.data;
          })
          this.editshowmodal = false;
        }).catch((err) => {
          console.log(err)
          // this.errorMessage = err.response.data.errors;
          this.$bvToast.toast(""+err.response.data.errors+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });
          this.editshowmodal = false;
        })
      }
      this.editSubmitted = false;
    },
    hideModal() {
      this.section.name = "";
      this.section.status = "1";
      this.submitted = false;
      this.showmodal = false;
    },
    hideEditModal() {
      this.editSubmitted = false;
      this.editshowmodal = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="text-transform:uppercase !important;">
            <div class="btn-toolbar">
              <a href="javascript:void(0);" class="btn btn-primary mb-2 mr-1"  @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> Add
              </a>
            </div>
            <b-alert show dismissible variant="success" v-if="successMessage">
              <i class="mdi mdi-check-all mr-2"></i>{{successMessage}}
            </b-alert>
            <b-alert show dismissible variant="danger" v-if="errorMessage">
              <i class="mdi mdi-block-helper mr-2"></i>{{ errorMessage }}
            </b-alert>
            <b-tabs nav-class="nav-tabs-custom">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                    class="table-centered"
                    :items="sectionData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    striped
                    sort-icon-left
                >
                  <template v-slot:head(checkbox)>
                    <div class="custom-control custom-checkbox">
                      <input
                          type="checkbox"
                          class="custom-control-input"
                          v-model="isCheckAll"
                          :id="`customercheck`"
                          @click='checkAll'
                      />
                      <label class="custom-control-label" :for="`customercheck`"></label>
                    </div>
                  </template>
                  <template v-slot:cell(pageSection)="row">
                    <p class="badge font-size-12">
                      {{row.item.pageSection}}
                    </p>
                  </template>
                  <template v-slot:cell(status)="row">
                    <div v-if="row.value == 1"
                         class="badge font-size-12 badge-soft-success"

                    >Active</div>
                    <div v-if="row.value == 0"
                         class="badge font-size-12 badge-soft-danger"
                    >In-Active</div>
                  </template>
                  <template v-slot:cell(slNo)="row">
                   {{row.index+1}}
                  </template>
                  <template v-slot:cell(checkbox)="row">
                    <div class="custom-control custom-checkbox">
                      <input
                          type="checkbox"
                          class="custom-control-input"
                          v-model="checkbox"
                          :id="`customercheck${row.index}`"
                          :value="row.item.id"
                      />
                      <label class="custom-control-label" :for="`customercheck${row.index}`">&nbsp;</label>
                    </div>
                  </template>
                  <template v-slot:cell(action) = "data">
                    <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        @click="editModal(data.item.id)"
                        v-b-tooltip.hover
                        title="Edit"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a
                        href="javascript:void(0);"
                        class="text-danger"
                        @click="deleteVendor(data.item.id)"
                        v-b-tooltip.hover
                        title="Delete"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          aria-controls
                      >
                      </b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              <!--              </b-tab>-->
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Add -->
    <b-modal
        id="modal-1"
        v-model="showmodal"
        title="ADD SECTION"
        title-class="text-dark font-18"
        hide-footer
        @close="hideModal"
    >

      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="name">SECTION NAME</label>
          <input
              id="name"
              v-model="section.name"
              type="text"
              class="form-control"
              placeholder="ENTER SECTION NAME"
              :class="{ 'is-invalid': submitted && $v.section.name.$error }"
              style="text-transform: uppercase !important;"
          />
          <div v-if="submitted && $v.section.name.$error" class="invalid-feedback">
            <span v-if="!$v.section.name.required">SECTION NAME IS REQUIRED</span>
            <span v-if="!$v.section.name.minLength">SECTION NAME SHOULD BE ATLEAST 3 CHARACTER</span>
          </div>
        </div>

        <div class="form-group">
          <label for="position">STATUS</label>
          <select class="form-control"
                  id="position"
                  v-model="section.status"
                  :class="{ 'is-invalid': submitted && $v.section.status.$error }">
            <option value="1">ACTIVE</option>
            <option value="0">IN-ACTIVE</option>
          </select>
          <div
              v-if="submitted && !$v.section.status.required"
              class="invalid-feedback"
          >STATUS IS REQUIRED</div>
        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-success">SAVE</button>
          <b-button class="ml-1" variant="danger" @click="hideModal">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->


    <!-- Modal Edit -->
    <b-modal
        id="modal-1"
        v-model="editshowmodal"
        title="EDIT SECTION"
        title-class="text-dark font-18"
        hide-footer
        @close="hideEditModal"
    >
      <form @submit.prevent="handleUpdate">
        <div class="form-group">
          <label for="edit_name">SECTION NAME</label>
          <input
              id="edit_name"
              v-model="editSection.name"
              type="text"
              class="form-control"
              placeholder="ENTER SECTION NAME"
              :class="{ 'is-invalid': editSubmitted && $v.editSection.name.$error }"
              style="text-transform: uppercase !important;"
          />
          <div v-if="editSubmitted && $v.editSection.name.$error" class="invalid-feedback">
            <span v-if="!$v.editSection.name.required">SECTION NAME IS REQUIRED is required</span>
            <span v-if="!$v.editSection.name.minLength">SECTION NAME SHOULD BE ATLEAST 3 CHARACTER</span>
          </div>
        </div>

        <div class="form-group">
          <label for="xyz">STATUS</label>
          <select class="form-control"
                  id="xyz"
                  v-model="editSection.status"
                  :class="{ 'is-invalid': editSubmitted && $v.editSection.status.$error }">
            <option value="1">ACTIVE</option>
            <option value="0">IN-ACTIVE</option>
          </select>
          <div
              v-if="editSubmitted && !$v.editSection.status.required"
              class="invalid-feedback"
          >STATUS IS REQUIRED</div>
        </div>


        <div class="text-right">
          <button type="submit" class="btn btn-success">UPDATE</button>
          <b-button class="ml-1" variant="danger" @click="hideEditModal">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>